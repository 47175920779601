<template>
    <div class="finish-wrap">
        <div class="logo">
            <img src="../assets/images/bentley_logo_a-c1@2x.png"  alt="" />
        </div>
        <div class="video-box">
            <img :src="`${aliyunOssUrl}/base/training/hk_learning_title.jpg`"  alt="" />
        </div>
        <div v-if="trainingList && trainingList.length > 0">
            <van-collapse v-model="activeNames">
                <div v-for="it in trainingList" :key="it.week">
                    <van-collapse-item v-if="it.detailList && it.detailList.length > 0" :title="it.week" size="large" :name="it.week">
                        <div v-for="item in it.detailList" :key="item.serialNo" class="training-row" @click="clickPane(item)">
                            <div class="training-left">
                                <img :src=item.imageUrl  alt="" class="img"/>
                                <img v-if="item.trainingType==1" src="../assets/images/bigplay.png" alt="" class="img2"/>
                            </div>
                            <div class="training-right">
                                <span class="span1">{{ toTraditional(item.title) }}</span>
                                <span class="span2">{{ toTraditional(item.content) }}</span>
                            </div>
                        </div>
                    </van-collapse-item>
                </div>
            </van-collapse>
        </div>
        <div v-else class="no-data">
            <span>即將上線，敬請期待！</span>
        </div>
    </div>
</template>
<script>
import {Landing} from '@/network'
import { Collapse, CollapseItem } from 'vant'
import {toTraditional} from '@/utils'

export default {
    data() {
        return {
            hubId: sessionStorage.getItem('hubId') || null,
            phone: sessionStorage.getItem('phone') || null,
            trainingList: null,
            activeNames: [],
            detailLogId: null,
            aliyunOssUrl: process.env.VUE_APP_ALIYUN_OSS_URL,
            toTraditional
        }
    },
    async created() {
        this.trainingList = await Landing.getTrainingList()
        this.trainingList.forEach(element => {
            this.activeNames.push(element.week)
        });
    },
    components: {
        vanCollapse: Collapse,
        vanCollapseItem: CollapseItem
    },
    methods: {
        clickPane(item) {
            console.log(item.serialNo + "   " + item.title + "  " + item.trainingType)
            switch (item.trainingType) {
                case 1:
                    this.actionVideo(item)
                    break
                case 2:
                    this.actionQuiz(item)
                    break
                case 3:
                    this.actionSalesTalk(item)
                    break
            }
        },
        async actionVideo(item) {
            console.warn('开始视频播放  ', item.videoId)
            this.detailLogId = await this.addTrainingDetailLog(item)
            this.$router.push({name: 'VideoContent', params: {videoId: item.videoId, detailLogId: this.detailLogId}})
        },
        actionQuiz(item) {
            console.log('开始进入问卷')
            if (item.fileUrl) {
                window.location.href = item.fileUrl + '&openid=' + this.hubId + '&_locale=zh_HK'
                this.addTrainingDetailLog(item)
            }
        },
        actionSalesTalk(item) {
            console.log('开始下载话术  url = ', item.fileUrl)
            let link = document.createElement("a")
            link.style.display = "none"
            link.href = item.fileUrl
            link.setAttribute('download', '')
            document.body.appendChild(link)
            this.addTrainingDetailLog(item)
            link.click()
        },
        addTrainingDetailLog(item) {
            return Landing.addTrainingDetailLog({
                hubId: this.hubId,
                phone: this.phone,
                trainingClassifyId: item.trainingClassifyId,
                trainingType: item.trainingType
            })
        }
    }
}
</script>

<style lang="less" >
.van-cell--large {
    padding: 5px 2px;
}
.van-collapse-item__content {
    background-color: #efeef3;
    padding: 2px 0px;
}
.van-cell {
    background-color: #efeef3;
}
</style>

<style lang="scss" scoped>
.finish-wrap{
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.logo {
    width: 375px;
    height: 80px;
    background-color: white;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    img {
        width: 84px;
        height: 42px;
        margin: 20px 20px;
    }
}
.video-box {
    display: flex;
    justify-content: center;
    padding: 0px 0px 10px;
    img {
        width: 100%;
        max-height: 180px;
        // border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
.training-row {
    height: 90px;
    background-color: #fff;
    align-items: center;
    // border-radius: 5px;
    margin: 5px 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .training-left {
        width: 180px;
        height: 68px;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
        margin-left: 12px;
        .img {
            width: 93%;
            height: 100%;
            border-radius: 5px;
        }
        .img2 {
            position: absolute;
            width: 45px;
            height: 45px;
            left: 33px;
        }
    }
    .training-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 10px;
        .span1 {
            color: rgba(0,50,32,0.7);
            font-size: 1.1em;
            // text-decoration:underline;
            margin-bottom: 10px;
        }
        .span2 {
            color: rgba(0, 0, 0, 0.5);
            font-size: 0.8em;
        }
    }
}
.no-data {
    height: calc(100vh - 280px);
    display: flex;
    justify-content: center;
    // align-items: center;
    span {
        margin-top: 2.5em;
        font-size: 1.5em;
        color: rgba(0,50,32,0.7);
    }
}
</style>